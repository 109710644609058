<template>
    <step-wrapper
        :index="index"
        :active="active"
        :valid="valid"
        :step="name"
        :title="title"
        :description="description"
        :hide-articles="true">
        <complete v-if="!active" />
        <non-complete v-else />
        <styled-button
            v-if="active"
            :disabled="!valid"
            class="mt-3"
            small
            @click="goToNextStep">
            Continue
        </styled-button>
    </step-wrapper>
</template>

<script>
import StepWrapper from '@/components/ad-deployment/components/StepWrapper';
import { mapState, mapActions } from 'vuex';
import stepProps from '@/components/ad-deployment/steps/stepProps';
import NonComplete from '@/components/ad-deployment/steps/DynamicDeployment/AdDestination/NonComplete.vue';
import Complete from '@/components/ad-deployment/steps/DynamicDeployment/AdDestination/Complete.vue';
import StyledButton from '@/components/globals/StyledButton.vue';

export default {
    name: 'DynamicDeploymentAdDestination',
    components: {
        StyledButton,
        NonComplete,
        Complete,
        StepWrapper,
    },
    props: stepProps,
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        ...mapState({
            deploymentDealerName: (state) => state.adDeployment.dealer_name
        }),
        title() {
            return this.$t('adDeployment.dynamicAdDestination.title');
        },
        description() {
            return this.$t('adDeployment.dynamicAdDestination.description', { dealerName: this.deploymentDealerName });
        }
    },
    methods: {
        ...mapActions({
            goToNextStep: 'adDeployment/goToNextStep'
        }),
    }
};
</script>

<style lang="scss" scoped>
</style>
