<template>
    <div class="complete-container">
        <div class="header">
            <div class="step mr-2">
                <span class="step__title">DESTINATION</span>
                <span class="step__info">{{ translateRadioGroupOption }}</span>
            </div>
            <edit-button step="ad_destination" />
        </div>
        <platform-ad-destination-exhibition
            v-for="(platform, index) in activePlatforms"
            v-show="!isDefaultRadioGroupOption"
            :key="index"
            :platform="platform" />
    </div>
</template>

<script>
import EditButton from '@/components/ad-deployment/components/EditButton.vue';
import PlatformAdDestinationExhibition
    from '@/components/ad-deployment/steps/DynamicDeployment/AdDestination/PlatformAdDestinationExhibition/index.vue';
import { mapGetters, mapState } from 'vuex';

export default {
    name: 'AdDestinationComplete',
    components: {
        PlatformAdDestinationExhibition,
        EditButton
    },
    computed: {
        ...mapState({
            radioGroupOption: (state) => state.adDeployment.ad_destination.radio_group_option,
        }),
        ...mapGetters({
            activePlatforms: 'adDeployment/activePlatforms',
        }),
        isDefaultRadioGroupOption() {
            return this.radioGroupOption === 'default';
        },
        translateRadioGroupOption() {
            if (this.isDefaultRadioGroupOption) {
                return 'Website VDPs';
            }

            return 'Customize';
        }
    }
};
</script>

<style lang="scss" scoped>
.complete-container {
    .header {
        display: flex;

        .step {
            width: 350px;
            display: flex;
            justify-content: space-between;
            background: white;
            padding: 10px 20px 10px 15px;
            border: 1px solid #DFDFE0;
            margin-bottom: 26px;

            &__title {
                font-weight: 700;
                margin-right: 15px;
            }

            &__info {
                color: #03A2EA;
                font-style: italic;
            }
        }
    }

    .ad-destination-exhibition {
        margin-bottom: 7px;
    }
}
</style>
