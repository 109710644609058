<template>
    <div class="ad-destination-exhibition">
        <icon
            class="mr-3"
            :name="platform.icon"
            size="20"
            color="#8F9EA6" />
        <div class="header">
            <span class="header__title">DESTINATION</span>
            <span class="header__name">{{ destination }}</span>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon.vue';

export default {
    name: 'PlatformAdDestinationExhibition',
    components: {
        Icon
    },
    props: {
        platform: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            options: {
                websiteVDPs: 'Website VDPs',
                leadForm: 'Lead Form',
                onFacebookVDPs: 'On-Facebook VDPs',
            },
        };
    },
    computed: {
        destination() {
            return this.options[this.$store.state.adDeployment.ad_destination.platform_specific[this.platform.name].radio_group_option];
        },
    },
};
</script>

<style lang="scss" scoped>
.ad-destination-exhibition {
    width: 350px;
    display: flex;
    align-items: center;

    .header {
        width: 350px;
        height: 37px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: white;
        padding: 10px 20px 10px 15px;
        border: 1px solid #DFDFE0;

        &__title {
            font-weight: 700;
            margin-right: 15px;
        }

        &__name {
            color: #03A2EA;
            font-style: italic;
        }
    }

}
</style>
