<template>
    <div class="dynamic-ad-destination">
        <icon
            class="mr-5"
            :name="platform.icon"
            size="20"
            color="#8F9EA6" />
        <div class="radio-group-container">
            <div
                v-for="(option, index) in platformDefaults[platform.name]"
                :key="index"
                class="radio-tooltip mr-4">
                <styled-radio
                    class="mr-1"
                    :value="radioValue"
                    :input-value="option"
                    :label="options[option]"
                    :name="platform.name + 'AdDestination'"
                    :disabled="shouldDisableRadio"
                    dot-style
                    inline
                    @input="radioValue = option" />
                <ad-deployment-tooltip
                    :content-path="`adDeployment.dynamicAdDestination.tooltips.${option}`"
                    :content-variables="{ platform: platform.displayName, dealerName: deploymentDealerName }" />
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon.vue';
import { PLATFORM_FACEBOOK, PLATFORM_PINTEREST, PLATFORM_SNAPCHAT, PLATFORM_TIKTOK } from '@/helpers/globals';
import StyledRadio from '@/components/globals/StyledRadio.vue';
import AdDeploymentTooltip from '@/components/ad-deployment/components/AdDeploymentTooltip.vue';
import { mapMutations, mapState } from 'vuex';

export default {
    name: 'PlatformAdDestination',
    components: {
        AdDeploymentTooltip,
        Icon,
        StyledRadio
    },
    props: {
        platform: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            options: {
                websiteVDPs: 'Website VDPs',
                leadForm: 'Lead Form',
                onFacebookVDPs: 'On-Facebook VDPs',
            },
            platformDefaults: {
                [PLATFORM_FACEBOOK]: ['websiteVDPs', 'leadForm', 'onFacebookVDPs'],
                [PLATFORM_PINTEREST]: ['websiteVDPs'],
                [PLATFORM_TIKTOK]: ['websiteVDPs'],
                [PLATFORM_SNAPCHAT]: ['websiteVDPs'],
            },
        };
    },
    computed: {
        ...mapState({
            deploymentDealerName: (state) => state.adDeployment.dealer_name,
            ads: state => state.adDeployment.ads,
        }),
        radioValue: {
            get() {
                return this.$store.state.adDeployment.ad_destination.platform_specific[this.platform.name].radio_group_option;
            },
            set(value) {
                if (value !== 'websiteVDPs') {
                    this.$emit('set-radio-custom');
                }

                this.setAdDeploymentValue({
                    key: `ad_destination.platform_specific.${this.platform.name}.radio_group_option`,
                    value
                });
            }
        },
        shouldDisableRadio() {
            return this.platformDefaults[this.platform.name].length === 1;
        },
        platformOptions() {
            return this.platformDefaults[this.platform.name];
        }
    },
    created() {
        this.$parent.$on('show-default-product-group', () => {
            this.radioValue = 'websiteVDPs';
        });
    },
    methods: {
        ...mapMutations({
            setAdDeploymentValue: 'adDeployment/setAdDeploymentValue'
        }),
    }
};
</script>

<style lang="scss" scoped>
.dynamic-ad-destination {
    display: flex;
    align-items: center;

    .radio-group-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .radio-tooltip {
            display: flex;

            label {
                margin: 0;
            }
        }
    }
}
</style>
